
import Vue from "vue";
import IImportApp from "@/lib/interfaces/import-app";
import ImportAppsList from "@/components/subviews/import-apps/import-apps-list.vue";
import ImportAppsMaintenance from "@/components/subviews/import-apps/import-apps-maintenance.vue";

export default Vue.extend({
  name: "ImportApps",

  components: { ImportAppsList, ImportAppsMaintenance },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      importApp: {} as IImportApp,
    };
  },
});
