
import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import IImportApp from "@/lib/interfaces/import-app";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";

export default Vue.extend({
  name: "ImportAppsList",

  components: {
    ABtn,
    ACard,
    ADataTable,
    ATextField,
    vAlertMessage,
    ASelectStatus,
  },

  props: {
    importApp: {
      type: Object as PropType<IImportApp>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",
      desativado: "0",

      options: {} as DataOptions,
      importApps: new Array<IImportApp>(),

      headers: [
        { text: "Descrição", value: "descricao", sortable: false },
        { text: "Endpoint", value: "endpoint", sortable: false },
        { text: "proc_run", value: "proc_run", sortable: false },
        { text: "Status", value: "cDesativado", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getImportApps().then();
    },

    options: {
      deep: true,
      handler() {
        this.getImportApps().then();
      },
    },
  },

  computed: {
    cImportApps(): Array<Record<string, unknown>> {
      return this.importApps.map((importApp) => ({
        ...importApp,

        cDesativado: importApp.desativado === "0" ? "Ativo" : "Inativo",
      }));
    },
  },

  methods: {
    async openImportApp(id: string) {
      const importApp = this.importApps.find(
        (importApp) => importApp.id === id
      );
      this.$emit("update:import-app", { ...importApp });
    },

    async getImportApps() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("aplicacao.read", {
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.importApps = rsp.getTable("aplicacao")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
