
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq, Table } from "@/lib/amdt";
import { required, maxLength } from "@/lib/validations";
import WorgError from "@/lib/worg-error";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ACard from "@/components/elements/a-card.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ABtn from "@/components/elements/a-btn.vue";
import VForm from "@/lib/types/v-form";
import IImportApp from "@/lib/interfaces/import-app";

export default Vue.extend({
  name: "ImportAppMaintenance",

  components: {
    ABtn,
    ASwitch,
    ACard,
    ATextField,
    vAlertMessage,
    ABtnDialogConfirm,
  },

  props: {
    importApp: {
      type: Object as PropType<IImportApp>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      dataImportApp: {
        id: "",
        descricao: "",
        endpoint: "",
        proc_run: "",
        desativado: "0",
      } as IImportApp,
    };
  },

  watch: {
    importApp: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";
        this.dataImportApp = {
          ...this.importApp,
        };
      },
    },
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    cTextSave(): string {
      return this.dataImportApp.id ? "Alterar" : "Salvar";
    },
  },
  methods: {
    maxLength,
    required,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("aplicacao.write");
        const tblCusto = new Table("aplicacao");

        const obj = {
          ...this.dataImportApp,
          id: this.dataImportApp.id || "default",
        };
        tblCusto.setColsFromObject(obj);
        tblCusto.addRowsFromObject(obj);

        rq.addTable(tblCusto);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Já existe uma aplicação com esses dados";
          return;
        }

        this.ret_msg = `Valor ${
          this.dataImportApp.id ? "atualizado" : "cadastrado"
        } com sucesso`;

        this.dataImportApp.id = rsp.ret_id.toString();

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.dataImportApp = {
        id: "",
        descricao: "",
        endpoint: "",
        proc_run: "",
        desativado: "0",
      };

      this.form.resetValidation();
    },
  },
});
